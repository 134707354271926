<template>
  <div class="timetable-card" :style="`--etaColor: ${onTime(item).color}`">
    <div class="timetable-time">
      {{ item.time }}
      <span class="wifi-logo" />
    </div>

    <div class="timetable-text">
      {{ item.destination }}
    </div>
    <div class="timetable-eta">
      {{ onTime(item).text }}
    </div>

    <div class="timetable-info">
      {{ item.info }}
    </div>

    <div class="delay-info">
      {{ onTime(item).delay_info }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'MTimetableCard',
  props: {
    item: Object
  },
  methods: {
    trimDate (dateString) {
      return dateString.slice(11, 16)
    },
    onTime (item) {
      const { delay_info, delay, cancelled } = item
      if (cancelled) return { text: this.$t('train.timetable.cancelled'), color: '#D81c1C', delay_info }
      if (delay) return { this: this.$t('train.timetable.delayed'), color: '#FF8400', delay_info }
      return { text: this.$t('on_time'), color: '#86C542' }
    }
  }
}
</script>

<style lang="stylus" scoped>
.timetable-card
  display grid
  grid-template-columns 80px auto
  grid-template-rows 1fr 1fr auto
  padding 5px 0
  align-items center

.timetable-time
  display flex
  align-items center

@keyframes wifi {
  0% {
    content ""
  }
  25% {
    content "\f6aa"
  }
  50% {
    content "\f6ab"
  }
  75% {
    content "\f1eb"
  }
}

@keyframes fade-in {
  from {opacity: 0}
  to {opacity: 1}
}

.wifi-logo
  height 20px
  display inline-block
  transform rotate(90deg)
  margin-left 4px
  font-size 85%

.wifi-logo::before,
.wifi-logo::after
  font-family "Font Awesome 5 Pro";
  font-weight 550
  position: absolute;
  transform: translateY(-50%) translateX(-50%);

.wifi-logo::before
  content ""
  animation wifi 3s infinite linear
  animation-delay 0.4s

.wifi-logo::after
  content "\f1eb"
  animation fade-in 3s infinite linear

.delay-info
  grid-column span 2
  font-size 90%

.timetable-time,
.timetable-eta,
.delay-info
  color var(--etaColor)

.timetable-text
  font-weight 450

.timetable-info,
.timetable-eta
  font-size 95%
</style>
